<template>
  <div v-if="datatableTargets === null">
    <ProgressSpinner />
  </div>
  <template v-else>
    <Dialog
      v-model:visible="displayBidRulesDialog"
      :header="dialogBidRuleTitle"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}"
      :style="{width: '80vw'}"
    >
      <TargetBidRules :entries="displayBidRules" />
    </Dialog>
    <div v-if="datatableTargets.length === 0">
      <p>No rules were executed for this ad group.</p>
    </div>
    <DataTable
      v-else
      :loading="loading"
      :value="datatableTargets"
      responsive-layout="scroll"
      :scrollable="true"
      scroll-height="450px"
      scroll-direction="both"
      @sort="sortTable"
    >
      <Column
        field="target.name"
        header="Target/Keyword"
        :sortable="isSortable('target.name')"
        frozen
        class="target-name"
      >
        <template #body="slotProps">
          {{ slotProps.data.target.name }}
        </template>
      </Column>
      <Column
        v-if="!isSpecificCampaign"
        field="campaign.name"
        header="Campaign"
        frozen
        :sortable="isSortable('campaignName')"
        class="target-name"
      >
        <template #body="slotProps">
          {{ slotProps.data.campaign.name }}
        </template>
      </Column>
      <Column
        field="adgroup.name"
        header="Ad Group"
        frozen
        :sortable="isSortable('adGroup')"
        class="target-name"
      >
        <template #body="slotProps">
          {{ slotProps.data.adGroup?.name ?? "x" }}
        </template>
      </Column>
      <Column
        field="target.state"
        header="Status"
      >
        <template #body="slotProps">
          <span :class="'campaign-badge status-' + slotProps.data.target.state">{{ slotProps.data.target.state }}</span>
        </template>
      </Column>
      <Column
        field="originalBid"
        header="Original Bid"
        :sortable="isSortable('originalBid')"
        class="justify-content-center"
      >
        <template #body="slotProps">
          {{ getMonetaryValue(getOriginalBid(slotProps.data.entries)) }}
        </template>
      </Column>
      <Column
        field="finalBid"
        header="Final Bid"
        :sortable="isSortable('finalBid')"
        class="justify-content-center"
      >
        <template #body="slotProps">
          {{ getMonetaryValue(getFinalBid(slotProps.data.entries)) }}
        </template>
      </Column>
      <Column
        field="executionDate"
        header="Execution date"
        :sortable="isSortable('executionDate')"
        class="target-name"
      >
        <template #body="slotProps">
          {{ getExecutionDate(slotProps.data.entries ) }}
        </template>
      </Column>
      <Column
        field="impressions"
        header="Impressions"
        :sortable="isSortable('impressions')"
        class="justify-content-center"
      />
      <Column
        field="clicks"
        header="Clicks"
        :sortable="isSortable('clicks')"
        class="justify-content-center"
      />
      <Column
        field="ctr"
        header="CTR"
        :sortable="isSortable('ctr')"
        class="justify-content-center"
      >
        <template #body="slotProps">
          {{ isNumberPresent(slotProps.data.ctr ) ? `${slotProps.data.ctr }%` : "No data" }}
        </template>
      </Column>
      <Column
        field="cost"
        header="Spend"
        :sortable="isSortable('cost')"
        class="justify-content-center"
      >
        <template #body="slotProps">
          {{ getMonetaryValue(slotProps.data.cost) }}
        </template>
      </Column>
      <Column
        field="cpc"
        header="CPC"
        :sortable="isSortable('cpc')"
        class="justify-content-center"
      >
        <template #body="slotProps">
          {{ getMonetaryValue(slotProps.data.cpc) }}
        </template>
      </Column>
      <Column
        field="orders"
        header="Orders"
        :sortable="isSortable('orders')"
        class="justify-content-center"
      />
      <Column
        field="sales"
        header="Sales"
        :sortable="isSortable('sales')"
        class="justify-content-center"
      >
        <template #body="slotProps">
          {{ getMonetaryValue(slotProps.data.sales) }}
        </template>
      </Column>
      <Column
        field="acos"
        header="ACOS"
        :sortable="isSortable('acos')"
        class="justify-content-center"
      >
        <template #body="slotProps">
          {{ isNumberPresent(slotProps.data.acos ) ? `${slotProps.data.acos }%` : "No data" }}
        </template>
      </Column>
      <Column
        field="royalties"
        :sortable="isSortable('royalties')"
        class="justify-content-center"
      >
        <template #header>
          <EstimatedSalesRoyaltiesHeader />
        </template>
        <template #body="slotProps">
          {{ getMonetaryValue(slotProps.data.royalties) }}
        </template>
      </Column>
      <Column
        field="kenpRoyalties"
        header="KENP Royalties"
        :sortable="isSortable('kenpRoyalties')"
        class="justify-content-center"
      >
        <template #body="slotProps">
          {{ getMonetaryValue(slotProps.data.kenpRoyalties) }}
        </template>
      </Column>
      <Column
        field="adjustedRevenue"
        :sortable="isSortable('adjustedRevenue')"
        class="justify-content-center"
      >
        <template #header>
          <AdjustedRevenueHeader />
        </template>
        <template #body="slotProps">
          {{ isNumberPresent(slotProps.data.adjustedRevenue ) ? getMonetaryValue(slotProps.data.adjustedRevenue) : "No data" }}
        </template>
      </Column>
      <Column
        field="estimatedAcor"
        :sortable="isSortable('estimatedAcor')"
        class="justify-content-center"
      >
        <template #header>
          <EstimatedAcorHeader />
        </template>
        <template #body="slotProps">
          {{ isNumberPresent(slotProps.data.estimatedAcor ) ? `${slotProps.data.estimatedAcor }%` : "No data" }}
        </template>
      </Column>
      <Column
        field="bidrules"
        header="Applied Rules"
        class="applied-bid-rule"
      >
        <template
          #body="slotProps"
        >
          <div v-if="slotProps.data.entries?.[0]?.message === 'NO_DATA'">
            No data
          </div>
          <Button
            v-else
            class="p-button-sm p-button-secondary"
            :disabled="displayBidRulesDialog"
            @click="() => handleDisplayBidRulesDialog(slotProps.data.target, slotProps.data.entries)"
          >
            See more
          </Button>
        </template>
      </Column>
    </DataTable>
  </template>
</template>

<script>
import dayjs from 'dayjs';
import TargetBidRules from './bidrules/TargetBidRules.vue';
import formatDecimal, { currencySymbolFromCode } from '../../../../../utils/formatUtils';
import EstimatedSalesRoyaltiesHeader from '../../../table/EstimatedSalesRoyaltiesHeader.vue';
import EstimatedAcorHeader from '../../../table/EstimatedAcorHeader.vue';
import AdjustedRevenueHeader from '../../../table/AdjustedRevenueHeader.vue';
import { formatTargetingType } from '../../../../../utils/reports/targetReportUtils';

// TODO: Move mapping to BE once DB is restructured
const FIELD_SORT_MAP = {
  clicks: 'executionData.clicks',
  impressions: 'executionData.impressions',
  kenpRoyalties: 'executionData.kindleEditionNormalizedPagesRoyalties14d',
  orders: 'executionData.purchases14d',
  sales: 'executionData.sales14d',
  royalties: 'executionData.kpis.royalties',
  acos: 'executionData.kpis.acos',
  ctr: 'executionData.kpis.ctr',
  cost: 'executionData.cost',
  cpc: 'executionData.kpis.cpc',
  estimatedAcor: 'executionData.kpis.trueAcos',
  adjustedRevenue: 'executionData.kpis.adjustedRevenue',
  originalBid: 'change.originalBid',
  finalBid: 'change.finalBid',
  executionDate: 'createdDate',
};

export default {
  components: {
    TargetBidRules, EstimatedSalesRoyaltiesHeader, EstimatedAcorHeader, AdjustedRevenueHeader,
  },
  props: {
    loading: {
      type: Boolean,
    },
    isSpecificCampaign: {
      type: Boolean,
    },
    targets: {
      type: Object,
      required: true,
    },
  },
  emits: ['sort'],
  data() {
    return {
      displayName: null,
      displayBidRulesDialog: false,
      displayBidRules: null,
    };
  },
  computed: {
    dialogBidRuleTitle() {
      return `Bid rules for ${this.displayName}`;
    },
    datatableTargets() {
      return (this.targets ?? []).map(this.mapTargets);
    },
  },
  methods: {
    isSortable(prop) {
      return !!FIELD_SORT_MAP[prop];
    },
    mapTargets(target) {
      // TODO: Do refactor after removing paginator
      const datatableTarget = JSON.parse(JSON.stringify(target));
      datatableTarget.target.name = this.formatTargetText(target.target);
      datatableTarget.originalBid = target.entries?.[0]?.change?.originalBid ?? 'No data';
      datatableTarget.finalBid = target.entries?.[target.entries.length - 1]?.change?.finalBid ?? 'No data';
      datatableTarget.executionDate = this.getNonFormattedExecutionDate(target.entries);

      // Metrics
      const metrics = target.entries?.[0]?.executionData ?? {};
      datatableTarget.clicks = metrics?.clicks ?? 'No data';
      datatableTarget.ctr = formatDecimal(metrics.ctr, 2);
      datatableTarget.cost = formatDecimal(metrics.cost, 2);
      datatableTarget.impressions = metrics?.impressions ?? 'No data';
      datatableTarget.orders = metrics?.purchases14d ?? 'No data';
      datatableTarget.sales = formatDecimal(metrics?.sales14d, 2) ?? 'No data';
      datatableTarget.kenpRoyalties = formatDecimal(metrics?.kindleEditionNormalizedPagesRoyalties14d, 2) ?? 'No data';
      datatableTarget.cpc = formatDecimal(metrics?.cpc, 2) ?? 'No data';
      datatableTarget.estimatedAcor = formatDecimal(metrics?.trueAcos, 2) ?? 'No data';
      // TODO: Delete unneeded data
      // datatableTarget.keywordBid = formatDecimal(metrics?.keywordBid, 2) ?? 0;
      datatableTarget.royalties = formatDecimal(metrics?.royalties) ?? 'No data';
      datatableTarget.acos = formatDecimal(metrics?.acos) ?? 'No data';
      datatableTarget.adjustedRevenue = formatDecimal(metrics?.adjustedRevenue) ?? 'No data';

      return datatableTarget;
    },
    handleDisplayBidRulesDialog(target, entries) {
      this.displayBidRulesDialog = true;
      this.displayBidRules = entries.filter((entry) => entry.targetId === (target.keywordId ?? target.targetId));
      this.displayName = this.formatTargetText(target);
    },
    formatTargetText(target) {
      return formatTargetingType(target);
    },
    getOriginalBid(entries) {
      const sucessfulEntries = entries.filter((entry) => entry.executed);
      let bid;
      if (sucessfulEntries.length === 0) {
        bid = entries?.[0]?.change?.originalBid;
      } else {
        bid = sucessfulEntries?.[0]?.change?.originalBid;
      }

      if (bid) {
        return formatDecimal(bid);
      }
      return 'No Data';
    },
    getFinalBid(entries) {
      const successfulEntries = entries.filter((entry) => entry.executed);
      let bid;
      if (successfulEntries.length === 0) {
        bid = entries?.[entries.length - 1]?.change?.finalBid;
      } else {
        bid = successfulEntries?.[successfulEntries.length - 1]?.change?.finalBid;
      }

      if (bid) {
        return formatDecimal(bid);
      }
      return 'No Data';
    },
    getExecutionDate(entries) {
      if (entries.length === 0) {
        return 'No data';
      }
      const firstEntry = entries[0];
      const date = firstEntry.executionDate ?? firstEntry.createdDate;

      return date ? dayjs(date).format('YYYY-MM-DD') : 'No data';
    },
    sortTable(event) {
      this.$emit('sort', { field: FIELD_SORT_MAP[event.sortField], dir: event.sortOrder === 1 ? 'asc' : 'desc' });
    },
    getNonFormattedExecutionDate(entries) {
      if (entries.length === 0) {
        return null;
      }
      const firstEntry = entries[0];
      if (!firstEntry.executionDate) {
        return null;
      }
      return new Date(entries[0].executionDate);
    },
    getMonetaryValue(value) {
      if (this.isNumberPresent(value)) {
        return `${currencySymbolFromCode(this.$store.state.auth.selectedProfile.currencyCode)}${value}`;
      }
      return 'No data';
    },
    isNumberPresent(value) {
      return value !== 'NaN';
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../assets/styles/dashboard/campaigns.scss";
</style>
<style>
.target-name {
  min-width: 200px;
}
th, td {
  width: 120px;
}
</style>
