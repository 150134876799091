<template>
  <div>
    <ul>
      Impressions: {{ metrics.impressions }}
    </ul>
    <ul>
      Clicks: {{ metrics.clicks }}
    </ul>
    <ul>
      CTR: {{ formatDecimal(metrics.ctr, 2) }}%
    </ul>
    <ul>
      Spend: {{ getMonetaryValue(formatDecimal(metrics.cost, 2)) }}
    </ul>
    <ul>
      CPC: {{ getMonetaryValue(formatDecimal(metrics.cpc, 2)) }}
    </ul>
    <ul>
      Orders: {{ metrics.purchases14d }}
    </ul>
    <ul>
      Sales: {{ getMonetaryValue(formatDecimal(metrics.sales14d, 2) ) }}
    </ul>
    <ul>
      ACOS: {{ formatDecimal(metrics.acos, 2) }}%
    </ul>
    <ul>
      Estimated Sales Royalties: {{ getMonetaryValue(formatDecimal(metrics.royalties, 2)) }}
    </ul>
    <ul>
      KENP Royalties: {{ getMonetaryValue(formatDecimal(metrics.kindleEditionNormalizedPagesRoyalties14d, 2)) }}
    </ul>
    <ul>
      Adjusted Revenue: {{ getMonetaryValue(formatDecimal(metrics.adjustedRevenue, 2)) }}
    </ul>
    <ul>
      Estimated ACOR: {{ formatDecimal(metrics.trueAcos, 2) }}%
    </ul>
  </div>
</template>

<script>
import formatDecimal, { currencySymbolFromCode } from '../../../../../utils/formatUtils';

export default {
  components: { },
  props: {
    metrics: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  methods: {
    formatDecimal(decimal) {
      return formatDecimal(decimal, 2);
    },
    getMonetaryValue(value) {
      return `${currencySymbolFromCode(this.$store.state.auth.selectedProfile.currencyCode)}${value}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../assets/styles/dashboard/campaigns.scss";
</style>
