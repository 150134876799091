<template>
  <SelectButton
    v-model="selectedRuleType"
    :options="ruleTypes"
    option-label="name"
    class="pb-3"
  />

  <keep-alive>
    <TargetBidRulesList
      v-if="selectedRuleType.value === 'INCREASE'"
      :bid-rules="increaseRules"
    />
  </keep-alive>

  <keep-alive>
    <TargetBidRulesList
      v-if="selectedRuleType.value === 'DECREASE'"
      :bid-rules="decreaseRules"
    />
  </keep-alive>

  <keep-alive>
    <TargetBidRulesList
      v-if="selectedRuleType.value === 'PAUSE_ENABLED'"
      :bid-rules="pauseEnableRules"
    />
  </keep-alive>

  <keep-alive>
    <TargetBidRulesList
      v-if="selectedRuleType.value === 'AUTOMATE_BID_OPTIMIZATION'"
      :bid-rules="autoRules"
    />
  </keep-alive>
</template>

<script>
import TargetBidRulesList from './list/TargetBidRulesList.vue';

const RULE_TYPES = [
  { name: 'Increase', value: 'INCREASE' },
  { name: 'Decrease', value: 'DECREASE' },
  { name: 'Pause/Enable', value: 'PAUSE_ENABLED' },
  { name: 'Automate', value: 'AUTOMATE_BID_OPTIMIZATION' },
];

export default {
  components: { TargetBidRulesList },
  props: {
    entries: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      increaseRules: this.entries.filter((entry) => entry.bidRule.result.type === 'INCREASE'),
      decreaseRules: this.entries.filter((entry) => entry.bidRule.result.type === 'DECREASE'),
      pauseEnableRules: this.entries.filter((entry) => ['PAUSE', 'RE_ENABLE'].includes(entry.bidRule.result.type)),
      autoRules: this.entries.filter((entry) => entry.bidRule.result.type === 'AUTOMATE_BID_OPTIMIZATION'),
      ruleTypes: RULE_TYPES,
      selectedRuleType: RULE_TYPES[0],
    };
  },
};
</script>
