<template>
  <Accordion>
    <AccordionTab>
      <template #header>
        <p class="mb-0 mr-2">
          {{ bidRuleEntry.bidRule?.name ?? bidRuleRecurrence }}
        </p>
        <i
          v-if="bidRuleEntry.executed"
          class="pi pi-check text-green-500"
        />
        <i
          v-else
          class="pi pi-times text-red"
        />
      </template>
      <div class="flex flex-row">
        <div class="flex flex-column flex-basis-50">
          <h3 class="font-bold text-xl">
            Bid Rule data
          </h3>
          <div class="flex flex-row mb-4">
            <p class="font-bold">
              IF
            </p>
            <ul>
              <li
                v-for="predicate in bidRuleEntry.bidRule.predicates"
                :key="predicate"
              >
                {{ formatPredicate(predicate) }}
              </li>
            </ul>
            <div class="ml-4">
              <span class="font-bold mr-1">
                THEN
              </span>
              <span>{{ formatResult(bidRuleEntry.bidRule.result) }}</span>
            </div>
          </div>
          <div class="flex flex-row mb-3">
            {{ formatExecuted(bidRuleEntry.executed, bidRuleEntry?.message) }}
          </div>
          <div
            v-if="bidRuleEntry.change"
            class="flex flex-column"
          >
            <div>
              <span class="font-bold">Original bid: </span><span>{{ originalBid }}</span>
            </div>
            <div><span class="font-bold">Final bid: </span><span>{{ finalBid }}</span></div>
          </div>
        </div>

        <div class="flex flex-column flex-basis-50">
          <h3 class="font-bold text-xl">
            Execution metrics
          </h3>
          <TargetMetricsTable :metrics="bidRuleEntry.executionData" />
        </div>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script>
import TargetMetricsTable from '../../TargetsMetricsTable.vue';
import { isDayOfWeekRecurrence } from '../../../../../../../utils/bidRuleUtils';
import {
  RECURRENCE_TYPES, DAYS_OF_WEEK, METRICS, METRIC_OPERANDS, RESULT_TYPES, VALUE_TYPES, EXECUTION_RESULTS,
} from '../../../../../../../constants/bidRulesConstants';
import formatDecimal, { currencySymbolFromCode } from '../../../../../../../utils/formatUtils';

export default {
  components: { TargetMetricsTable },
  props: {
    bidRuleEntry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    bidRuleRecurrence() {
      const recurrenceType = RECURRENCE_TYPES.find(type => type.value === this.bidRuleEntry.bidRule.recurrence.type).name;
      let weekday;
      if (isDayOfWeekRecurrence(this.bidRuleEntry.bidRule.recurrence.type)) {
        weekday = DAYS_OF_WEEK.find((day) => day.value === this.bidRuleEntry.bidRule.recurrence.dayOfWeek).name;
      } else {
        weekday = '';
      }

      return `${recurrenceType} ${weekday} bid rule`;
    },
    originalBid() {
      return this.bidRuleEntry?.change?.originalBid ? `${currencySymbolFromCode(this.$store.state.auth.selectedProfile.currencyCode)}${formatDecimal(this.bidRuleEntry.change.originalBid)}` : 'N/A';
    },
    finalBid() {
      return this.bidRuleEntry?.change?.finalBid ? `${currencySymbolFromCode(this.$store.state.auth.selectedProfile.currencyCode)}${formatDecimal(this.bidRuleEntry.change.finalBid)}` : 'N/A';
    },
  },
  methods: {
    formatPredicate(predicate) {
      const predicateMetric = METRICS.find((metric) => metric.value === predicate.metric).name;
      const predicateOperand = METRIC_OPERANDS.find((operand) => operand.value === predicate.operand).name;
      const predicateValue = predicate.value;
      return `${predicateMetric} ${predicateOperand} ${predicateValue}`;
    },
    formatResult(result) {
      const resultType = RESULT_TYPES.find(type => type.value === result.type);
      const resultValue = result.value.value;
      const resultValueType = VALUE_TYPES.find(type => type.value === result.value.type).name;

      if (resultType.value === 'PAUSE' || resultType.value === 'RE_ENABLE' || resultType.value === 'AUTOMATE_BID_OPTIMIZATION') {
        return resultType.name;
      }

      if (result.value.type === 'FIXED') {
        return `${resultType.name} ${currencySymbolFromCode(this.$store.state.auth.selectedProfile.currencyCode)}${resultValue}`;
      }

      return `${resultType.name} ${resultValue}${resultValueType}`;
    },
    formatExecuted(executed, message) {
      let executedMessage;
      if (executed) {
        executedMessage = 'Rule was executed successfully.';
      } else {
        executedMessage = 'Rule was not executed';
      }

      if (message) {
        return `${executedMessage}: ${EXECUTION_RESULTS.find((result) => result.value === message).name}`;
      }

      return `${executedMessage}`;
    },
  },
};
</script>
<style scoped>
  .flex-basis-50 {
   flex-basis: 50%;
  }
  .text-red {
    color: red;
  }
</style>
